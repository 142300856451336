import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslate } from "react-redux-multilingual";

import PlaybackSpeedSlider from "./SpeedSlider";
import StartTimeOffsetDialog from "./StartTimeOffsetDialog";

import ReplaceButton from "../buttons/ReplaceButton";
import UndoButton from "../buttons/UndoButton";
import RedoButton from "../buttons/RedoButton";
import SpellCheckButton from "../buttons/SpellCheckButton";
import HighlightButton from "../buttons/HighlightButton";
import SubtitleButton from "../buttons/SubtitleButton";
import Loading from "../ui/Loading";
import { EditIcon } from "../ui/Icons";
import FileTranslationPicker from "./fileTranslationPicker/FileTranslationPicker";

import { getDate, getTimeFromSec } from "../../helpers/timeUtils";

import "./EditorControls.scss";
import UpdateSubtitleRules from "../header/UpdateSubtitleRules";
import { DOCUMENT_TYPE_TRANSCRIPT } from "../../sagas/constants";
import ShowOrigininalButton from "../buttons/ShowOrigininalButton";
import { isSourceLanguageSupportedForMachineMade } from "../editor/helpers";
import { getMachineTranslationLanguages } from "../MachineTranslations/api/machineTranslations";

const editIconStyle = {
  fontSize: "15px",
  marginLeft: 1,
  verticalAlign: "middle",
};
const isSmallScreen = window.innerWidth < 768;

class EditorControls extends Component {
  state = {
    openDialog: false,
  };

  handleOpen = () => {
    this.setState({ openDialog: true });
  };

  handleClose = () => {
    this.setState({ openDialog: false });
  };

  getAllowedLanguages = async () => {
    const result = await getMachineTranslationLanguages();
    this.setState({ allowedLang: result?.data?.sourceLanguages });
  };
  async componentDidMount() {
    this.props.addSteps({
      id: "startTime",
      textTranslate: "TOUR.EDITOR.startTime",
      selector: ".EditorControls__startTimeOffset",
      position: "bottom",
      type: "hover",
      allowClicksThruHole: false,
      style: {
        hole: {
          borderRadius: "100%",
        },
      },
    });
    await this.getAllowedLanguages();
  }

  render() {
    const {
      translate,
      onUndo,
      onRedo,
      setShowFindText,
      showFindText,
      addSteps,
      onHighlight,
      spellCheck,
      onConvertToSubtitles,
      uiSettings,
      onChangeSpellCheckFeatureStatus,
      undoSteps,
      redoSteps,
      isTranslateModalOpen,
      setTranslateModalOpen,
      showOriginal,
      allowShowOriginal,
      triggerShowOriginal,
      triggerHideOriginal,
      isPerfectJobEditor
    } = this.props;

    const { transcriptStatus: status } = this.props;
    const { transcriptAudio: audio } = this.props;
    const params = new URLSearchParams(window.location.search);
    // editor=transcript&jobType=captions -> No translation
    // editor=subtitles&jobType=captions -> Translation
    // editor=transcript&jobType=transcription -> Translation

    const canTranslate = () => {
      const lang = status?.data?.job?.language;

      if (lang) {
        try {
          const isAllowed = isSourceLanguageSupportedForMachineMade(
            lang,
            this.state.allowedLang
          );
  
          return isAllowed;
        } catch (error) {
          return false;
        }
      } else {
        return false;
      }
    };
    const isTranscriptionFile = () => {
      if (status && status.data && status.data.documentType) {
        return status.data.documentType === DOCUMENT_TYPE_TRANSCRIPT;
      } else {
        return false;
      }
    };

    const created = status.data ? getDate(status.data.created) : "";
    const currentTime = getTimeFromSec(audio.currentTime);
    const hasTranslations = status.data.translations.length > 0;
    const sourceJobDetails = status.data.sourceJob || {};

    if (hasTranslations) {
      sourceJobDetails.language = status.data?.languageText;
    }

    const { transcript, account } = this.props;
    const subtitlesEditorBetaAccess =
      account.data &&
      account.data.permissions &&
      account.data.permissions.subtitlesEditorBetaAccess;

    return (
      <div className="EditorControls">
        {(!status.loaded || !audio.loaded) && (
          <Loading
            message={translate("EDITOR.loadingAudio")}
            cover={true}
            size={20}
          />
        )}
        {status.loaded &&
          audio.loaded &&
          uiSettings.editorControls && [
            <div className="EditorControls__meta" key="ecm">
              <div className="EditorControls__created">
                <strong>{translate("EDITOR.metaCreated")}</strong>
                <em>{created}</em>
              </div>
              <div
                className="EditorControls__startTimeOffset"
                onClick={this.handleOpen}
              >
                <strong>
                  {/* <u> */}
                  {translate("EDITOR.metaStartTimeOffset")}
                  {/* </u> */}
                </strong>
                <em>
                  {getTimeFromSec(transcript.data.startTimeOffset)}
                  <EditIcon style={editIconStyle}></EditIcon>
                </em>
              </div>
              <div className="EditorControls__length">
                <strong>{translate("EDITOR.metaLength")}</strong>
                <em>
                  <time>{`${currentTime} / ${getTimeFromSec(
                    status.data.nrAudioSeconds
                  )}`}</time>
                </em>
              </div>
              <PlaybackSpeedSlider />
            </div>,
            <div className="EditorControls__buttons" key="ecb">
              {!isTranscriptionFile() && (
                <UpdateSubtitleRules
                  transcript={transcript}
                  transcriptStatus={status}
                  translate={translate}
                />
              )}
              {canTranslate() && (
                <FileTranslationPicker
                  jobId={this.state.jobId}
                  hasTranslations={hasTranslations}
                  translations={status.data.translations}
                  sourceJob={sourceJobDetails}
                  isTranslateModalOpen={isTranslateModalOpen}
                  setTranslateModalOpen={setTranslateModalOpen}
                  isPerfectJobEditor={isPerfectJobEditor}
                />
              )}
              {!isTranscriptionFile() &&
                !isSmallScreen &&
                allowShowOriginal && (
                  <ShowOrigininalButton
                    showOriginal={showOriginal}
                    triggerHideOriginal={triggerHideOriginal}
                    triggerShowOriginal={triggerShowOriginal}
                  />
                )}
              <SpellCheckButton
                spellCheck={spellCheck}
                onChangeSpellCheckFeatureStatus={
                  onChangeSpellCheckFeatureStatus
                }
                account={account}
              />

              <ReplaceButton
                setShowFindText={setShowFindText}
                showFindText={showFindText}
              />
              <UndoButton onUndo={onUndo} steps={undoSteps} />
              <RedoButton onRedo={onRedo} steps={redoSteps} />
              <HighlightButton onHighlight={onHighlight} addSteps={addSteps} />
              {subtitlesEditorBetaAccess && (
                <SubtitleButton onConvertToSubtitles={onConvertToSubtitles} />
              )}
            </div>,
            <StartTimeOffsetDialog
              key="ecs"
              open={this.state.openDialog}
              onClose={this.handleClose}
              onStartTimeOffsetChange={this.props.onStartTimeOffsetChange}
            />,
          ]}
      </div>
    );
  }
}

const mapStateToProps = ({
  transcriptStatus,
  transcriptAudio,
  transcript,
  account,
  uiSettings,
}) => ({
  transcriptStatus,
  transcriptAudio,
  transcript,
  account,
  uiSettings,
});

export default connect(mapStateToProps)(withTranslate(EditorControls));
