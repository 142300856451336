import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslate } from "react-redux-multilingual";

import EditorTour from "./editor/Tour";
import AmberTranscriptEditor from "./editor/TranscriptEditor";
import TranscriptEditor from "@amberscript/transcript-editor/dist/TranscriptEditor";
import AmberSubtitleEditor from "./editor/SubtitleEditor";

import SubtitleWavePlayer from "./player/SubtitleWavePlayer";
import TranscriptWavePlayer from "./player/TranscriptWavePlayer";

import Header from "./header/Header";
import Error from "./Error";
import Login from "./Login";
import ScrambledErrorDialog from "./ScrambledErrorDialog";
import EditorControls from "./editor-controls/EditorControls";
import Loading from "./ui/Loading";
import Footer from "./footer/Footer";
import IEnotice from "./IEnotice";
import Alert from "./ui/Alert";
import Snackbar from "./ui/Snackbar";
import SubtitleGuide from "./guideline/subtitleFlowGuide";

import isHotkey from "is-hotkey";

import {
  ADD_START_TIME_OFFSET,
  ADMIN,
  AUTH_LOGIN_FAIL,
  CONVERT_TO_SUBTITLES,
  DOCUMENT_TYPE_SUBTITLES_JSON,
  DOCUMENT_TYPE_TRANSCRIPT,
  FINISH_CONVERT_TO_SUBTITLES,
  GET_AUDIO,
  GET_JOB,
  GET_TRANSCRIPT,
  GET_TRANSCRIPT_STATUS,
  QUALITY_CONTROLLER,
  RELOAD_TRANSCRIPT,
  RELOAD_TRANSCRIPT_OK,
  RIGHT_PANEL_TAB_VALUE_PREQC,
  SAVE_TRANSCRIPT,
  SET_AUDIO_PLAY,
  SET_AUDIO_TOGGLE,
  SET_JOB_EDITOR_MODE,
  SET_TRANSCRIPT,
  SET_TRANSCRIPT_HIGHLIGHTS,
  TRANSCRIBER,
} from "../sagas/constants";
import { isMobileOrTabletDevice } from "../helpers/misc";
import {
  isPerfectJobEditor,
  isPerfectJobEditorOrAdmin,
} from "../helpers/accountUtil";
import { canCreateSubtitles, canEdit, isAdmin } from "../helpers/permissions";
import { TRANSCRIBER_DASHBOARD } from "../settings";
import RightPanel from "./right-panel/rightPanel";
import {
  checkSupportedLanguage,
  getSummaryCheck,
  submitJob,
} from "../sagas/qualityCheck";
import PreQCDialog from "./pre-qc/PreQCDialog";
import { redirectToTranscriberPlatform } from "../utils/redirect";
import { getDataForSummaryCheck } from "./pre-qc/helpers";
import TranscriptStatusError from "./errorView/transcriptStatusError";
import {
  PRE_QC_DOCUMENT_TYPE_SUBTITLES_JSON,
  PRE_QC_DOCUMENT_TYPE_TRANSCRIPT_JSON,
  MAX_CARS_SUMMARY_ALLOWED,
} from "./right-panel/pre-qc/constants";
import UserActivityTracker from "./userActivityTracker.js/UserActivityTracker";

import setupI18nextInit from "../i18n";
import { parseFeatureToggle } from "../helpers/featureToggles";
import SlateSubtitleEditor from "./editor/SlateSubtitleEditor";
import { TimeLineContainer } from "./timeline/TimeLineContainer";
import FindText from "./findText/findText";
import { apiClient } from "../apiClient";
import { getOriginalText } from "./editor/export/ExportApi";

let sendError = false;
window.slate_editor_release = "0.2.2";

class EditorView extends Component {
  state = {
    playing: false,
    previousTime: 0,
    currentTime: 0,
    tourSteps: [],
    tourReplay: false,
    showAlertNoEdit: false,
    shouldEditorBeReadOnly: false,
    showRealigningLoadingBar: false,
    isPreQCAvailable: false,
    spellCheck: false,
    isTranslate: false,
    openScrambledErrorDialog: false,
    getAudioCounter: 0,
    loadingQc: false,
    QCHasBeenRun: false,
    preQCIssues: {},
    openPreQCDialog: false,
    openPreQCDialogLoading:false,
    preQCConfirmation: false,
    rightPanelOpenTab: null,
    redoSteps: false,
    undoSteps: false,
    timeLineElement: null,
    waveform: null,
    showFindText: false,
    isExportModalOpen: false,
    isTranslateModalOpen: false,
    showOriginal: false,
    originalIsReturned: false,
  };

  constructor(props) {
    super(props);

    this.onSeekAndCenterAudio = this.onSeekAndCenterAudio.bind(this);
    this.setShowFindText = this.setShowFindText.bind(this);
    document.addEventListener("keydown", (event) => {
      // Check if the key combination for find command is pressed
      if ((event.ctrlKey || event.metaKey) && event.key === "f") {
        // Prevent the default behavior of the find command
        event.preventDefault();
        this.setShowFindText(!this.state.showFindText);
      }
    });
  }

  get isSlateTranscriptEditor() {
    // return new URLSearchParams(window.location.search).get('beta') === 'true' && this.isTranscriptionFile(); // when this line is active, old editor is default.
    return (
      new URLSearchParams(window.location.search).get("beta") !== "false" &&
      this.isTranscriptionFile()
    ); // when this line is active, new slate js editor is default
  }

  get isSlateSubtitleEditor() {
    //return new URLSearchParams(window.location.search).get('beta') === 'true' && this.isCaptionFile(); when this line is active, old editor is default.
    return (
      new URLSearchParams(window.location.search).get("beta") !== "false" &&
      this.isCaptionFile()
    ); // when this line is active, new slate js editor is default
  }

  get isSlateEditor() {
    return this.isSlateTranscriptEditor || this.isSlateSubtitleEditor;
  }

  set showRealigningLoadingBar(value) {
    this.setState({
      showRealigningLoadingBar: value,
    });
  }

  setExportModalOpen = (value) =>
    this.setState({ ...this.state, isExportModalOpen: value });

  setTranslateModalOpen = (value) =>
    this.setState({ ...this.state, isTranslateModalOpen: value });

  triggerShowOriginal = async () => {
    await this.fetchTranscriptJson();
    this.setState({ showOriginal: true });
  };

  triggerHideOriginal = () => {
    this.setState({ showOriginal: false });
  };
  onTimelineDoubleClick = (editorInterface, event, time) => {
    if (event.altKey) {
      if (this.wavePlayer?.getWrappedInstance().isReady()) {
        editorInterface.setAudioPlayerTime(time, true, false);
        this.wavePlayer.getWrappedInstance().doSeekAndCenter(time);
      }
      this.props.dispatch({ type: SET_AUDIO_PLAY });
    } else {
      this.props.dispatch({ type: SET_AUDIO_TOGGLE });
    }
  };

  async componentDidUpdate(prevProps) {
    if (
      this.editor &&
      this.editor.timeLineElement !== this.state.timeLineElement
    )
      this.setState({ timeLineElement: this.editor.timeLineElement });
    // Set waveform data if there are
    if (this.props.transcriptStatus) {
      const waveform = this.state.waveform;
      const transcriptStatusData = this.props.transcriptStatus.data;
      if (transcriptStatusData?.hasWaveformPeaks) {
        if (
          waveform?.waveformData !== transcriptStatusData.waveformPeaksData ||
          waveform?.waveformTimeLength !== transcriptStatusData.nrAudioSeconds
        ) {
          this.setState({
            waveform: {
              waveformData: transcriptStatusData.waveformPeaksData,
              waveformTimeLength: transcriptStatusData.nrAudioSeconds,
            },
          });
        }
      } else if (this.props.transcriptAudio) {
        const transcriptAudioData = this.props.transcriptAudio.data;
        if (
          transcriptAudioData?.downloadUrl &&
          transcriptAudioData.downloadUrl !== waveform?.mediaDownloadUrl
        ) {
          this.setState({
            waveform: {
              mediaDownloadUrl: transcriptAudioData.downloadUrl,
            },
          });
        }
      }
    }
    if (
      prevProps.transcript.data.segments.size === 0 &&
      this.props.transcript.data.segments.size > 0
    ) {
    
      // Calculate the total length of characters across all words in the segments
      const totalCharacterCount = this.props.transcript.data.segments.reduce(
        (total, segment) =>
          total + segment.words.reduce((sum, word) => sum + word.text.length, 0),
        0
      );
      if (totalCharacterCount > MAX_CARS_SUMMARY_ALLOWED) {
        this.setState({ isPreQCAvailable: false });
      }
    }

  }

  componentDidMount() {
    //show no editing message if the user opened the editor with mobile or tablet.
    if (isMobileOrTabletDevice()) {
      this.setState({
        showAlertNoEdit: true,
        shouldEditorBeReadOnly: true,
      });
    }
    document.addEventListener("keydown", this.handleKeyboardEvent);

    // Setup i18n
    const { language } = this.props.account;
    setupI18nextInit(language);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyboardEvent);
  }

  // Checks if the Pre QC is available
  async checkPreQCAvailability(job) {
    const isLanguageSupported = await this.checkSupportedLanguage(job);

    return (
      isLanguageSupported &&
      (this.isTranscriber() || this.isQualityController() || this.props.admin)
    );
  }

  handleKeyboardEvent = (event) => {
    if (this.props.transcript.saveError) {
      return;
    }

    //Disable backspace for navigating in browsers
    if (
      event.code === "Backspace" &&
      (event.target || event.srcElement).tagName !== "TEXTAREA" &&
      (event.target || event.srcElement).tagName !== "INPUT" &&
      (!this.isSlateEditor || !this.editor.hasFocus)
    ) {
      event.preventDefault();
    }
    if (
      !event.ctrlKey &&
      !event.altKey &&
      !event.shiftKey &&
      !event.metaKey &&
      event.key === "Tab"
    ) {
      //Play or Pause the audio.
      event.preventDefault();
      this.onAudioToggle();
    }
    if (isHotkey("Control+space", event)) {
      //Play or Pause the audio.
      event.preventDefault();
      this.onAudioToggle();
    } else if (isHotkey("Control+r", event)) {
      //Rewind
      event.preventDefault();
      this.onRewind();
    } else if (this.isSlateEditor) {
      if (isHotkey("Control+g", event)) {
        // Toggle spellcheck
        event.preventDefault();
        this.onChangeSpellCheckFeatureStatus();
      }
    }
    if (!event.ctrlKey && event.altKey && !event.shiftKey && !event.metaKey) {
      // Rewind
      if (event.key === "ArrowLeft") {
        event.preventDefault();
        this.onRewind();
      }
      // Forward
      else if (event.key === "ArrowRight") {
        event.preventDefault();
        this.onForward();
      }
    }
    // Do this key binding only if the focus is not on the editor.
    if (
      !this.isSlateEditor && // Slate editor process Ctr-z/shift-Ctr-z by itself
      this.editor &&
      !this.editor
        .getWrappedInstance()
        .state.editorState.getSelection()
        .getHasFocus()
    ) {
      //only for MAC OS
      if (
        window.navigator.userAgent.indexOf("Mac") !== -1 &&
        !event.ctrlKey &&
        event.metaKey
      ) {
        // if(!event.shiftKey && event.key === 'g') {
        //     event.preventDefault();
        //     this.onChangeSpellCheckFeatureStatus();
        // }
        if (!event.shiftKey && event.key === "z") {
          event.preventDefault();
          this.onUndo();
        } else if (event.shiftKey && event.keyCode === 90) {
          event.preventDefault();
          this.onRedo();
        }
      }
      if (event.ctrlKey) {
        //Redo
        //changed z to 90 because when shift is pressed, z will be change to Z.
        if ((event.keyCode === 90 && event.shiftKey) || event.key === "y") {
          event.preventDefault();
          this.onRedo();
        }
        //Undo
        else if (event.key === "z" && !event.shiftKey) {
          event.preventDefault();
          this.onUndo();
        }
        //Toggle spell checker
        // else if (event.key === 'g' && !event.shiftKey) {
        //     event.preventDefault();
        //     this.onChangeSpellCheckFeatureStatus();
        // }
      }
    }
  };

  componentWillMount() {
    const { transcriptId } = this.props.match.params;
    this.setState({ transcriptId });

    if (isPerfectJobEditor(this.props.roles)) {
      this.props.dispatch({ type: SET_JOB_EDITOR_MODE });
    }

    // Fetch job if roles are respected
    if (isPerfectJobEditorOrAdmin(this.props.roles)) {
      this.props.dispatch({ type: GET_JOB, jobId: transcriptId });
    }
    this.props.dispatch({ type: GET_TRANSCRIPT_STATUS, transcriptId });
  }

  componentWillReceiveProps(nextProps) {
    this.setUndoRedoState();
    const { transcript, transcriptAudio, transcriptStatus, job } = nextProps;

    if (job.redirectTo) {
      window.location.href = job.redirectTo;
      return;
    }

    if (
      transcript.saveError &&
      transcript.saveError !== this.props.transcript.saveError
    ) {
      this.props.dispatch({ type: AUTH_LOGIN_FAIL });
    }

    if (transcriptStatus.loaded) {
      if ((transcript.error || transcriptAudio.error) && !sendError) {
        sendError = true;
        // sendErrorToSlack(transcript, transcriptAudio, transcriptStatus);
      }
    }
    // Do the GET_AUDIO after GET_TRANSCRIPT_STATUS because GET_AUDIO need the answer
    // of GET_TRANSCRIPT_STATUS to realize that use getaudio or getaudiolink
    if (nextProps.transcriptStatus.data && !this.props.transcriptStatus.data) {
      const { storageProvider } = nextProps.transcriptStatus.data;

      this.props.dispatch({
        type: GET_AUDIO,
        transcriptId: this.state.transcriptId,
        storageProvider,
      });

      const { documentType, transcriptionType } =
        nextProps.transcriptStatus.data;
      this.props.dispatch({
        type: GET_TRANSCRIPT,
        transcriptId: this.state.transcriptId,
        documentType,
      });

      this.addParamInUrl(
        "editor",
        documentType === "SUBTITLES_JSON" ? "subtitles" : "transcript"
      );
      this.addParamInUrl("jobType", transcriptionType);
    }

    if (
      this.props.transcript.data.startTimeOffset !==
      nextProps.transcript.data.startTimeOffset
    ) {
      if (
        !this.props.transcript.data.highlights.size &&
        !this.props.transcript.data.segments.size &&
        !this.props.transcript.data.speakers.size
      ) {
        // It's the constructor object -> false alarm, don't update
        return;
      }
      setTimeout(() => {
        this.props.dispatch({
          type: RELOAD_TRANSCRIPT_OK,
        });
        setTimeout(() => {
          this.saveTranscript();
        }, 10);
      }, 10);
    }

    // If the current job is different from the job form nextProps
    if (
      this.props.job &&
      nextProps.job &&
      this.props.job.data !== nextProps.job.data
    ) {
      // Check if pre QC is available
      this.checkPreQCAvailability(nextProps.job)
        .then((response) => {
          if (response) {
            this.setState({
              isPreQCAvailable: response,
            });
          }
        })
        .catch(() => {});
    }
  }

  getSymbolByLocationSearch = () => {
    if (window.location.search.trim() === "") {
      return "?";
    } else {
      return "&";
    }
  };

  setShowFindText = (value) => {
    this.setState({
      showFindText: value,
    });
  };

  addParamInUrl = (key, value) => {
    const { history } = this.props;
    const searchParams = new URLSearchParams(window.location.search);
    if (!searchParams.get(key)) {
      history.replace({
        pathname: window.location.pathname,
        search: `${
          window.location.search
        }${this.getSymbolByLocationSearch()}${key}=${value}`,
      });
    }
  };

  onChangeSpellCheckFeatureStatus = () => {
    this.setState({
      spellCheck: !this.state.spellCheck,
    });
  };

  onAudioToggle = () => {
    this.props.dispatch({ type: SET_AUDIO_TOGGLE });
  };

  onRewind = () => {
    if (this.wavePlayer && this.wavePlayer.getWrappedInstance().isReady()) {
      this.wavePlayer.getWrappedInstance().doRewind();
    }
  };

  onForward = () => {
    if (this.wavePlayer && this.wavePlayer.getWrappedInstance().isReady()) {
      this.wavePlayer.getWrappedInstance().doForward();
    }
  };

  onSeekAndCenterAudio(time) {
    if (this.wavePlayer && this.wavePlayer.getWrappedInstance().isReady()) {
      this.wavePlayer.getWrappedInstance().doSeekAndCenter(time);
    }
  }

  setUndoRedoState() {
    this.setState({
      redoSteps: this.isSlateEditor ? !!this.editor?.redosCount : true,
      undoSteps: this.isSlateEditor ? !!this.editor?.undosCount : true,
    });
  }

  onUndo = () => {
    if (this.editor) {
      this.isSlateEditor
        ? this.editor.undo()
        : this.editor.getWrappedInstance().handleUndo();
    }
  };

  fetchSuggestion = async (options) => {
    return await apiClient.post(`ai/transform/captions/paraphrase`, options);
  };

  fetchTranscriptJson = async () => {
    if (!this.isTranscriptionFile() && !this.state.originalIsReturned) {
      const jobId =
        this.props.transcriptStatus?.data?.sourceJob?.id ||
        this.props.transcriptStatus?.data?.job?.id;
      if (jobId) {
        try {
          const transcriptJsonOriginal = await getOriginalText(jobId);
          // Now you can set this in the state if needed
          this.setState({ transcriptJsonOriginal });
          this.setState({ originalIsReturned: true });
        } catch (error) {
          console.error("Failed to load transcript JSON:", error);
        }
      }
    }
  };

  handleChangeDebounced = () => {
    this.saveTranscriptFromSlateEditor();
  };

  saveTranscriptFromSlateEditor = () => {
    const transcriptJson = this.getTranscriptJson();
    this.props.dispatch({
      type: SET_TRANSCRIPT,
      contentState: null,
      transcriptJson,
    });
    this.props.dispatch({
      type: SAVE_TRANSCRIPT,
      documentType: this.isTranscriptionFile()
        ? DOCUMENT_TYPE_TRANSCRIPT
        : DOCUMENT_TYPE_SUBTITLES_JSON,
      userName: this.props.account.userName,
      isTranscriber: this.isTranscriber(),
      isOnlyTranscriber: this.isOnlyTranscriber(),
      jobId: this.props.jobId,
      transcriptJson,
    });
  };

  getTranscriptJson = () => ({
    ...this.editor.transcript,
    id: this.props.transcript.id,
    recordId: this.props.transcript.recordId,
    isConvertedToSubtitles: this.props.transcript.data.isConvertedToSubtitles,
  });

  handleEditorAudioTimeChange = () => {
    this.onSeekAndCenterAudio(this.editor.audioPlayerTime);
  };

  handleHighlightChange = () => {
    this.props.dispatch({
      type: SET_TRANSCRIPT_HIGHLIGHTS,
      highlights: this.editor.transcript.highlights,
    });
  };

  handleSetAudioTimeSubtitle = (time, scroll = false, playing = false) => {
    if (this.editor && this.isSlateEditor) {
      this.editor.setAudioPlayerTime(
        time + this.props.transcript.data.startTimeOffset,
        scroll,
        true,
        playing
      );
    }
  };

  handleStartPlayingSubtitle = () => {
    if (this.editor && this.isSlateEditor) {
      this.editor.playingIsStarted();
    }
  };

  handleStopPlayingSubtitle = () => {
    if (this.editor && this.isSlateEditor) {
      this.editor.playingIsStopped();
    }
  };

  handleSetAudioTimeTranscript = (time, scroll = false) => {
    if (this.editor && this.isSlateEditor) {
      this.editor.setAudioPlayerTime(
        time + this.props.transcript.data.startTimeOffset,
        scroll
      );
    }
  };

  onRedo = () => {
    if (this.editor) {
      this.isSlateEditor
        ? this.editor.redo()
        : this.editor.getWrappedInstance().handleRedo();
    }
  };

  onHighlight = () => {
    if (this.editor) {
      this.isSlateEditor
        ? this.editor.triggerHighlight()
        : this.editor.getWrappedInstance().handleHighlight();
    }
  };

  onRealign = () => {
    if (this.editor) {
      if (this.isSlateEditor) {
        // Show loadingBar
        this.showRealigningLoadingBar = true;
        const time = Date.now();
        this.editor.adjustTimestamps().then(() => {
          const delay = 1000 + time - Date.now();
          if (delay < 0) this.showRealigningLoadingBar = false;
          else setTimeout(() => (this.showRealigningLoadingBar = false), delay);
        });
      } else {
        // Show loadingBar
        this.setState({
          showRealigningLoadingBar: true,
        });
        // Realign timestamps
        this.editor.getWrappedInstance().realignTranscript(() => {
          // Hide loadingBar
          setTimeout(() => {
            this.setState({
              showRealigningLoadingBar: false,
            });
          }, 1000);
        });
      }
    }
  };

  onStartTimeOffsetChange = (startTimeOffset) => {
    if (this.editor) {
      this.props.dispatch({
        type: RELOAD_TRANSCRIPT,
      });
      if (this.isSlateEditor) {
        this.editor.startTimeOffset = startTimeOffset;
        this.props.dispatch({
          type: ADD_START_TIME_OFFSET,
          transcriptJson: this.getTranscriptJson(),
          startTimeOffset,
        });
      } else {
        if (this.props.transcript.data.startTimeOffset === startTimeOffset) {
          return;
        }
        const editorState = this.editor.getWrappedInstance().state.editorState;
        this.props.dispatch({
          type: ADD_START_TIME_OFFSET,
          editorState,
          startTimeOffset,
        });
      }
    }
  };

  onConvertToSubtitles = (subtitleLength) => {
    if (this.editor) {
      this.saveTranscript();

      this.props.dispatch({
        type: CONVERT_TO_SUBTITLES,
        subtitleLength,
      });
      setTimeout(() => {
        this.props.dispatch({
          type: FINISH_CONVERT_TO_SUBTITLES,
        });
        this.editor.getWrappedInstance().handleConvertToSubtitles();
      }, 100);
    }
  };

  onPlaceCursor = (time) => {
    if (this.editor) {
      setTimeout(
        () => this.editor.getWrappedInstance().onPlaceCursor(time),
        100
      );
    }
  };

  onSeekTime = () => {
    if (!this.isSlateEditor && this.editor) {
      setTimeout(
        () => this.editor.getWrappedInstance().scrollToCurrentText(),
        100
      );
    }
  };

  addSteps = (newSteps) => {
    if (!Array.isArray(newSteps)) {
      newSteps = [newSteps];
    }

    if (!newSteps.length) {
      newSteps = [];
    }

    this.setState((state) => {
      state.tourSteps = state.tourSteps.concat(newSteps);
      return state;
    });
  };

  runEditorTour = () => {
    this.setState({
      tourReplay: true,
    });
    setTimeout(() => {
      this.setState({
        tourReplay: false,
      });
    }, 500);
  };

  updateAudioLink = () => {
    if (this.state.getAudioCounter >= 2) {
      return;
    }

    const { storageProvider } = this.props.transcriptStatus.data;
    this.props.dispatch({
      type: GET_AUDIO,
      transcriptId: this.state.transcriptId,
      storageProvider,
    });

    this.setState((prevState) => ({
      getAudioCounter: prevState.getAudioCounter + 1,
    }));
  };

  saveTranscript = () => {
    if (this.editor) {
      this.isSlateEditor
        ? this.saveTranscriptFromSlateEditor()
        : this.editor.getWrappedInstance().saveTranscript();
    }
  };

  updateSubtitlesInVideo = () => {
    if (this.editor) {
      this.isSlateEditor
        ? doUpdateSubtitlesInVideo(this.editor.subtitles)
        : this.editor.getWrappedInstance().updateSubtitlesInVideo();
    } else {
      // TODO: call updateSubtitlesInVideo after editor is created and filled out to avoid setTimeout
      setTimeout(this.updateSubtitlesInVideo.bind(this), 200);
    }
  };

  isCaptionFile = () => {
    if (
      this.props.transcriptStatus &&
      this.props.transcriptStatus.data &&
      this.props.transcriptStatus.data.documentType
    ) {
      return (
        this.props.transcriptStatus.data.documentType ===
        DOCUMENT_TYPE_SUBTITLES_JSON
      );
    } else {
      return false;
    }
  };

  isTranscriptionFile = () => {
    if (
      this.props.transcriptStatus &&
      this.props.transcriptStatus.data &&
      this.props.transcriptStatus.data.documentType
    ) {
      return (
        this.props.transcriptStatus.data.documentType ===
        DOCUMENT_TYPE_TRANSCRIPT
      );
    } else {
      return false;
    }
  };

  getLoadingMessage = () => {
    if (this.isCaptionFile()) {
      return "Loading subtitles...";
    } else if (this.isTranscriptionFile()) {
      return "Loading transcript...";
    } else {
      return "Loading file...";
    }
  };

  isTranscriber = () => {
    return this.props.roles.includes(TRANSCRIBER);
  };

  isOnlyTranscriber = () => {
    return (
      this.props.roles.includes(TRANSCRIBER) && this.props.roles.length === 1
    );
  };

  isQualityController = () => this.props.roles.includes(QUALITY_CONTROLLER);

  isAdmin = () => this.props.roles.includes(ADMIN);

  handleOpenScrambledErrorDialog = () => {
    this.setState({
      openScrambledErrorDialog: true,
    });
  };

  handleDismissInvalidJobTypeAlert = () => {
    window.location.href = TRANSCRIBER_DASHBOARD;
  };

  // Check if language is supported
  checkSupportedLanguage = async (job) => {
    const { language } = job.data;
    let isLanguageSupported = false;
    try {
      const response = await checkSupportedLanguage(language);
      if (response && response.data) {
        const { supported } = response.data;
        isLanguageSupported = supported;
      }
    } catch (error) {
    } finally {
      return isLanguageSupported;
    }
  };

  // Resets the state of preQCIssue
  resetPreQCIssuesState = () => {
    this.setState({
      preQCIssues: {
        issues: [],
      },
    });
  };

  // Returns document type string for PreQC
  getPreQCDocumentType = () => {
    let documentType = "";
    if (this.isTranscriptionFile()) {
      documentType = PRE_QC_DOCUMENT_TYPE_TRANSCRIPT_JSON;
    } else if (this.isCaptionFile()) {
      documentType = PRE_QC_DOCUMENT_TYPE_SUBTITLES_JSON;
    }
    return documentType;
  };

  /**
   * Gets the summary of all issues for the transcript
   * Can open PreQC dialog if needed
   * Can submit automatically if needed
   * @param {bool} openDialog
   * @param {bool} submitIfAllOK
   */
  getSummaryCheckTranscript = async (
    openDialog = false,
    submitIfAllOK = true
  ) => {
    const { language } = this.props.job.data;
    const { transcriptionStyle } = this.props.job.data.jobOptions;
    const { id, recordId } = this.props.transcript;
    const { speakers, segments } = this.props.transcript.data;
    const { transcriptionType } = this.props.job.data;
    const documentType = this.getPreQCDocumentType();

    const data = {
      ...getDataForSummaryCheck(speakers, segments),
      id,
      recordId,
    };
    this.setState({
      openPreQCDialog: openDialog,
      openPreQCDialogLoading: true
    });
    this.setLoadingQc(true);

    try {
      const response = await getSummaryCheck(
        language,
        transcriptionStyle,
        transcriptionType,
        documentType,
        data
      );
      if (response && response.data) {
        const { issue_total } = response.data;
        if (issue_total > 0) {
          this.setState({
            preQCIssues: response.data,
            openPreQCDialogLoading: false,
            openPreQCDialog: openDialog,
          });
        } else {
          // Reset the state
          this.resetPreQCIssuesState();
          if (submitIfAllOK) {
            await this.handleSubmitJob();
          }
        }
      }
    } catch (error) {
    } finally {
      this.setQCHasBeenRun(true);
      this.setLoadingQc(false);
      this.setState({
        openPreQCDialogLoading: false,
      });
    }
  };

  // Navigates between confirmation and normal state
  // in Pre QC Dialog
  handleChangePreQCConfirmation = (state) => {
    this.setState({
      preQCConfirmation: state,
    });
  };

  // Changes boolean to know if QC has already been run
  setQCHasBeenRun = (state) => {
    this.setState({
      QCHasBeenRun: state,
    });
  };

  // Changes the state of the loading button for Pre QC
  setLoadingQc = (state) => {
    this.setState({
      loadingQc: state,
    });
  };

  // Clicking on pre QC check button in header
  handleSubmitQc = async () => {
    this.setLoadingQc(true);

    if (this.state.isPreQCAvailable) {
      await this.getSummaryCheckTranscript(true);
    } else {
      this.setLoadingQc(false);
      this.handleSubmitJob();
      return;
    }
  };

  // Closes the Pre QC Dialog
  handleClosePreQcDialog = () => {
    this.setState({
      openPreQCDialog: false,
      preQCConfirmation: false,
    });
  };

  // Closes the Pre QC Dialog and opens PreQC panel
  handleClosePreQcDialogAndOpenPanel = () => {
    this.handleClosePreQcDialog();
    this.setState({
      rightPanelOpenTab: RIGHT_PANEL_TAB_VALUE_PREQC,
    });
  };

  // Resets the right panel opened tab order
  resetRightPanelOpenTab = () => {
    this.setState({
      rightPanelOpenTab: null,
    });
  };

  // Submits the transcript
  handleSubmitJob = async () => {
    const { jobId } = this.props.job.data;

    try {
      await submitJob(jobId);
      redirectToTranscriberPlatform();
    } catch (error) {
    } finally {
      this.handleClosePreQcDialog();
    }
  };

  getLanguage = () => (this.props.job.data ? this.props.job.data.language : "");

  getTranscriptDirection = () => {
    const editorLanguage = this.props.transcriptStatus?.data?.translations?.find(job => job.id === this.props.jobId)?.targetLanguageCode || this.props.language;
    return ["ar-sa", "ar", "he-il", "iw-il"].includes(editorLanguage) ? "rtl" : "auto";
  }

  render() {
    const { transcriptId } = this.props.match.params;
    const {
      transcript,
      transcriptJson,
      transcriptStatus,
      translate,
      uiSettings,
      roles,
      job,
      account,
    } = this.props;
    const { saveError, errorCode } = transcript;
    const { userName } = account;
    const TIME_TRACKER_ENABLED = parseFeatureToggle(
      process.env.REACT_APP_FF_TIME_TRACKER
    );

    const getJobId = () =>
      job && job.data && job.data.jobId ? job.data.jobId : null;

    const getGuideLines = () => {
      return (
        transcript.loaded &&
        transcriptStatus.loaded &&
        this.isTranscriptionFile() &&
        canCreateSubtitles(
          this.props.job,
          this.props.transcriptStatus,
          this.props.roles
        )
      );
    };

    const getJobIdByParam = () => {
      return this.props.location.pathname.slice(1);
    };

    const shouldTrackTime = () => {
      return (
        transcript.loaded &&
        transcriptStatus.loaded &&
        !this.state.openPreQCDialog &&
        !job.isRatingDialogOpen &&
        TIME_TRACKER_ENABLED
      );
    };

    const content = (
      <React.Fragment>
        <ScrambledErrorDialog
          open={this.state.openScrambledErrorDialog}
          translate={translate}
        />
        {this.isTranscriber() && (
          <PreQCDialog
            open={this.state.openPreQCDialog}
            openPreQCDialogLoading = {this.state.openPreQCDialogLoading}
            translate={translate}
            issues={this.state.preQCIssues.issue_type_totals}
            handleClose={this.handleClosePreQcDialog}
            handleViewAndFix={this.handleClosePreQcDialogAndOpenPanel}
            handleSubmit={this.handleSubmitJob}
            confirmation={this.state.preQCConfirmation}
            setConfirmation={this.handleChangePreQCConfirmation}
          />
        )}
        {shouldTrackTime() && (
          <UserActivityTracker
            audioPlaying={this.props.transcriptAudio}
            jobId={getJobIdByParam()}
          />
        )}
        {transcript.loaded && transcriptStatus.loaded && (
          <Header
            saveTranscript={this.saveTranscript}
            onRewind={this.onRewind}
            onForward={this.onForward}
            addSteps={this.addSteps}
            uiSettings={uiSettings}
            realignTranscript={this.onRealign}
            isTranscriptionFile={this.isTranscriptionFile}
            isCaptionFile={this.isCaptionFile}
            shouldShowCreateSubtitleBtn={() =>
              canCreateSubtitles(
                this.props.job,
                this.props.transcriptStatus,
                this.props.roles
              )
            }
            isTranscriber={this.isTranscriber}
            transcriptId={this.state.transcriptId}
            submitQc={this.handleSubmitQc}
            loadingQc={this.state.loadingQc}
            isExportModalOpen={this.state.isExportModalOpen}
            setExportModalOpen={this.setExportModalOpen}
            isPreQCAvailable={this.state.isPreQCAvailable}
          />
        )}
        {transcript.loaded && transcriptStatus.loaded && (
          <EditorControls
            spellCheck={this.state.spellCheck}
            onChangeSpellCheckFeatureStatus={
              this.onChangeSpellCheckFeatureStatus
            }
            onUndo={this.onUndo}
            onRedo={this.onRedo}
            undoSteps={this.state.undoSteps}
            redoSteps={this.state.redoSteps}
            onHighlight={this.onHighlight}
            onStartTimeOffsetChange={this.onStartTimeOffsetChange}
            onConvertToSubtitles={this.onConvertToSubtitles}
            addSteps={this.addSteps}
            setShowFindText={this.setShowFindText}
            showFindText={this.state.showFindText}
            isTranslateModalOpen={this.state.isTranslateModalOpen}
            setTranslateModalOpen={this.setTranslateModalOpen}
            showOriginal={this.state.showOriginal}
            triggerShowOriginal={this.triggerShowOriginal}
            allowShowOriginal={!this.isTranscriptionFile()}
            isPerfectJobEditor={isPerfectJobEditor(roles)}
          />
        )}
        {transcript.loaded &&
          transcriptStatus.loaded &&
          uiSettings.tour && ( // this can be removed to start the tour immediately
            <EditorTour
              steps={this.state.tourSteps}
              addSteps={this.addSteps}
              replay={this.state.tourReplay}
            />
          )}
        {transcriptStatus && transcriptStatus.data && this.isCaptionFile() && (
          <SubtitleWavePlayer
            ref={(ref) => (this.wavePlayer = ref)}
            transcriptId={transcriptId}
            onPlaceCursor={this.onPlaceCursor}
            onSeekTime={this.onSeekTime}
            startTimeOffset={this.props.transcript.data.startTimeOffset}
            updateAudioLink={this.updateAudioLink}
            updateSubtitlesInVideo={this.updateSubtitlesInVideo}
            onSetAudioTime={this.handleSetAudioTimeSubtitle}
            onStartPlaying={this.handleStartPlayingSubtitle}
            onStopPlaying={this.handleStopPlayingSubtitle}
          />
        )}
        {transcriptStatus &&
          transcriptStatus.data &&
          this.isTranscriptionFile() && (
            <TranscriptWavePlayer
              ref={(ref) => (this.wavePlayer = ref)}
              transcriptId={transcriptId}
              onPlaceCursor={this.onPlaceCursor}
              onSeekTime={this.onSeekTime}
              startTimeOffset={this.props.transcript.data.startTimeOffset}
              updateAudioLink={this.updateAudioLink}
              shouldShowCreateSubtitleBtn={() =>
                canCreateSubtitles(
                  this.props.job,
                  this.props.transcriptStatus,
                  this.props.roles
                )
              }
              onSetAudioTime={this.handleSetAudioTimeTranscript}
            />
          )}
        {getGuideLines() && (
          <SubtitleGuide
            isTranscriber={this.isTranscriber}
            translate={translate}
          />
        )}
        {!transcript.loading && transcript.loaded && (
          <React.Fragment>
            {this.state.showAlertNoEdit && (
              <Alert
                message={translate("EDITOR.noEditingForMobileOrTablet")}
                onDismiss={() => this.setState({ showAlertNoEdit: false })}
                onClose={() => this.setState({ showAlertNoEdit: false })}
              />
            )}
            {transcriptStatus &&
              transcriptStatus.data &&
              this.isCaptionFile() &&
              (this.isSlateEditor ? (
                <SlateSubtitleEditor
                  onTimelineDoubleClick={this.onTimelineDoubleClick}
                  waveform={this.state.waveform}
                  getInterface={(value) => {
                    this.editor = value;
                    window.verifyTimestamps = () => this.editor.test();
                  }}
                  transcript={transcriptJson}
                  transcriptJsonOriginal={this.state.transcriptJsonOriginal}
                  shouldShowCreateSubtitleBtn={() =>
                    canCreateSubtitles(
                      this.props.job,
                      this.props.transcriptStatus,
                      this.props.roles
                    )
                  }
                  translate={translate}
                  expanded={this.props.transcriptAudio.expanded}
                  isTranscriber={this.isTranscriber}
                  isVideo={this.props.isVideo}
                  language={this.props.language}
                  fetchSuggestion={this.fetchSuggestion}
                  isOrganization={Boolean(this.props.account.organizationId)}
                  translations={{
                    busy: translate("EDITOR.busy"),
                    msg: {
                      tooManyRows: translate("EDITOR.tooManyRows"),
                    },
                  }}
                  originalLang={
                    this.props.transcriptStatus?.data?.sourceJob
                      .languageTranslation ||
                    translate(`EDITOR_LANGUAGE.${this.props.language}`)
                  }
                  showOriginal={this.state.showOriginal}
                  triggerHideOriginal={this.triggerHideOriginal}
                  spellCheck={this.state.spellCheck}
                  onChange={() => {
                    this.setUndoRedoState();
                  }}
                  onChangeDebounced={() => {
                    this.updateSubtitlesInVideo();
                    this.handleChangeDebounced();
                  }}
                  onManualAudioTimeChange={this.handleEditorAudioTimeChange}
                  onHighlightChange={this.handleHighlightChange}
                  jobConfiguration={
                    transcriptStatus?.data?.job?.jobConfiguration
                  }
                  classNames={{
                    segment: "block blockSub",
                    time: "slateTimeSubtitle",
                    segmentLine: "txtSub slateTxtSub",
                    word: {
                      default: {
                        spoken: "spoken_word",
                        speaking_now: "about_to_be_said",
                        about_to_be_said: "about_to_be_said",
                      },
                      failed: {
                        spoken: "failed_spoken_word",
                        speaking_now: "failed_about_to_be_said",
                        about_to_be_said: "failed_about_to_be_said",
                      },
                    },
                    busy_indicator: "editor_busy_indicator",
                  }}
                />
              ) : (
                <AmberSubtitleEditor
                  ref={(ref) => (this.editor = ref)}
                  onKeyboardEvent={this.handleKeyboardEvent}
                  seekAndCenterAudio={this.onSeekAndCenterAudio}
                  addSteps={this.addSteps}
                  readOnly={this.state.shouldEditorBeReadOnly}
                  expanded={this.props.transcriptAudio.expanded}
                  translate={translate}
                  isPerfectJobEditor={isPerfectJobEditor(roles)}
                  onChangeSpellCheckFeatureStatus={
                    this.onChangeSpellCheckFeatureStatus
                  }
                  spellCheck={this.state.spellCheck}
                  isTranscriber={this.isTranscriber}
                  handleOpenScrambledErrorDialog={
                    this.handleOpenScrambledErrorDialog
                  }
                />
              ))}
            {transcriptStatus &&
              transcriptStatus.data &&
              this.isTranscriptionFile() &&
              (this.isSlateEditor ? (
                <div
                  className={"TranscriptEditorEnvelope"}
                  dir={this.getTranscriptDirection()}
                >
                  <TranscriptEditor
                    dir={this.getTranscriptDirection()}
                    getInterface={(value) => {
                      this.editor = value;
                      window.verifyTimestamps = () => this.editor.test();
                    }}
                    transcript={transcriptJson}
                    shouldShowCreateSubtitleBtn={() =>
                      canCreateSubtitles(
                        this.props.job,
                        this.props.transcriptStatus,
                        this.props.roles
                      )
                    }
                    expanded={this.props.transcriptAudio.expanded}
                    isTranscriber={this.isTranscriber}
                    isVideo={this.props.isVideo}
                    language={this.props.language}
                    translations={{
                      busy: translate("EDITOR.busy"),
                    }}
                    spellCheck={this.state.spellCheck}
                    onChange={() => {
                      this.setUndoRedoState();
                    }}
                    onChangeDebounced={this.handleChangeDebounced}
                    onManualAudioTimeChange={this.handleEditorAudioTimeChange}
                    onHighlightChange={this.handleHighlightChange}
                    classNames={{
                      editor_box: "TranscriptEditor",
                      editor_fg: "TranscriptEditor__fg",
                      segment: "block",
                      speaker: "TranscriptEditorBlockSpeaker",
                      time: "time",
                      text: "txt",
                      word: {
                        spoken: "spoken_word",
                        speaking_now: "about_to_be_said",
                        about_to_be_said: "about_to_be_said",
                      },
                      busy_indicator: "editor_busy_indicator",
                    }}
                  />
                  {/* Push out the transcript editor from under the footer */}
                  <div className={"footer_main_base"} />
                </div>
              ) : (
                <AmberTranscriptEditor
                  ref={(ref) => (this.editor = ref)}
                  onKeyboardEvent={this.handleKeyboardEvent}
                  seekAndCenterAudio={this.onSeekAndCenterAudio}
                  addSteps={this.addSteps}
                  readOnly={this.state.shouldEditorBeReadOnly}
                  expanded={this.props.transcriptAudio.expanded}
                  translate={translate}
                  isPerfectJobEditor={isPerfectJobEditor(roles)}
                  onChangeSpellCheckFeatureStatus={
                    this.onChangeSpellCheckFeatureStatus
                  }
                  spellCheck={this.state.spellCheck}
                  shouldShowCreateSubtitleBtn={() =>
                    canCreateSubtitles(
                      this.props.job,
                      this.props.transcriptStatus,
                      this.props.roles
                    )
                  }
                  isTranscriber={this.isTranscriber}
                  handleOpenScrambledErrorDialog={
                    this.handleOpenScrambledErrorDialog
                  }
                />
              ))}
            {/* Tab Panel on the right side */}
            <RightPanel
              guideLines={getGuideLines()}
              translate={translate}
              glossaryId={this.props.transcriptStatus.data.glossaryId}
              isPreQCAvailable={this.state.isPreQCAvailable}
              loadingQC={this.state.loadingQc}
              setLoadingQC={this.setLoadingQc}
              QCHasBeenRun={this.state.QCHasBeenRun}
              preQCIssues={this.state.preQCIssues}
              runPreQC={this.getSummaryCheckTranscript}
              language={this.getLanguage()}
              openTabValue={this.state.rightPanelOpenTab}
              resetOpenTabValue={this.resetRightPanelOpenTab}
              jobId={getJobId()}
              userName={userName}
              roles={roles}
            />
            {saveError && (errorCode === 403 || errorCode === 400) && (
              <Login saveTranscript={this.saveTranscript} />
            )}
          </React.Fragment>
        )}
        {transcript.loading && <Loading message={this.getLoadingMessage()} />}
        {!transcript.loading && transcript.error && (
          <Error message="Could not load transcript..." />
        )}
        {transcript.empty && (
          <Error message={translate("EDITOR.errorEmptySegments")} />
        )}
      </React.Fragment>
    );

    if (
      transcriptStatus.error &&
      transcriptStatus.errorCode === 403 &&
      this.isTranscriber()
    ) {
      return <TranscriptStatusError />;
    }

    return (
      <React.Fragment>
        {content}
        <IEnotice />
        {this.state.showFindText && (
          <FindText
            transcriptJson={this.props.transcriptJson}
            editor={this.editor}
            onSeekAndCenterAudio={this.onSeekAndCenterAudio}
          />
        )}
        <Snackbar />
        {this.state.timeLineElement && (
          <TimeLineContainer>
            {this.state.timeLineElement}
          </TimeLineContainer>
        )}
        <Footer
          saveTranscript={this.saveTranscript}
          addSteps={this.addSteps}
          onEditorTourClick={this.runEditorTour}
          enableTour={uiSettings.tour}
          enableShortcuts={uiSettings.shortcuts}
          showRealigningLoadingBar={this.state.showRealigningLoadingBar}
          realignTranscript={this.onRealign}
          isCaptionFile={this.isCaptionFile}
          setExportModalOpen={this.setExportModalOpen}
          editor={this.editor}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  transcriptStatus,
  transcriptAudio,
  transcript,
  uiSettings,
  account,
  job,
}) => ({
  transcriptStatus: transcriptStatus,
  isVideo: transcriptStatus.data && transcriptStatus.data.isVideo,
  transcriptAudio,
  transcript,
  transcriptJson: transcript.transcriptJson,
  uiSettings,
  roles: account.data && account.data.roles,
  admin: isAdmin(account.data),
  job,
  account: account.data,
  jobId: job?.data?.jobId || transcriptStatus?.data?.job?.id,
  language: transcriptStatus.data && transcriptStatus.data.language,
});

export function doUpdateSubtitlesInVideo(subs) {
  const root = document.getElementById("amber-video-player");
  if (root) {
    const video = root.firstElementChild;
    let track;

    if (video) {
      // Remove and old track if existed
      if (video.textTracks.length > 0) {
        track = video.textTracks[video.textTracks.length - 1];
        [...track.cues].forEach((cue) => track.removeCue(cue));
      } else {
        track = video.addTextTrack("captions");
      }
      track.mode = "showing";
      for (const sub of subs) {
        const filteredSubs = sub.subs
          .map((s) => s.trim())
          .filter((s) => s !== "");
        if (filteredSubs.length > 0) {
          track.addCue(
            new VTTCue(sub.startTime || 0, sub.endTime, filteredSubs.join("\n"))
          );
        }
      }
    }
  }
}

export default connect(mapStateToProps)(withTranslate(EditorView));
